






















































































































































































































































































import Vue from "vue";
import Navbar from "@/components/Navbar.vue";
import FooterVue from "@/components/FooterVue.vue";
export default Vue.extend({
  name: "Preguntas",
  components: {
    Navbar,
    FooterVue,
  },
  data: () => ({
    panel: [0, 1, 2, 3, 4, 5],
  }),
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  props: {
    merchant: {},
  },
  methods: {},
});
